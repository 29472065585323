<template>
  <Modal
    footer-hide
    :value="value"
    title="菜单排序"
    @input="(val) => $emit('input', val)"
    @on-visible-change="modalShow"
  >
    <div style="weight:100%;height:350px;">
      <x-table
        style="height:320px;overflow:hidden"
        @on-expand-tree="expandChange"
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
        rowKey="id"
      ></x-table>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    roleId: [String, Number],
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "菜单名称",
            minWidth: 100,
            tree: true,
            key: "name",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row, index }) => {
              return (
                <div>
                  {index != 0 && (
                    <a
                      onClick={() => this.moveUp(row)}
                      style="margin-right: 10px"
                    >
                      上移
                    </a>
                  )}
                  {!row.end && <a onClick={() => this.moveDown(row)}>下移</a>}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      dataMap: new Map(),
      expandSet: new Set(),
    };
  },
  methods: {
    expandChange(id, status) {
      if (!status) this.expandSet.delete(id);
      else this.expandSet.add(id);
    },
    moveDown(row) {
      let indexKey = row.indexKey;
      let temparr = indexKey.split("-");
      if (temparr.length <= 1) return;
      let lastNum = temparr[temparr.length - 1];
      temparr.splice(temparr.length - 1, 1, +lastNum + 1);
      let upRow = this.dataMap.get(temparr.join("-"));
      if (upRow) {
        this.$post(this.$api.ROLE.AUTHORITY_SORT, {
          roleId: this.roleId,
          menuType: "wfpt",
          menuIds: row.id + "," + upRow.id,
        }).then(() => {
          this.getAuthorityMenu();
        });
      }
    },
    moveUp(row) {
      let indexKey = row.indexKey;
      let temparr = indexKey.split("-");
      if (temparr.length <= 1) return;
      let lastNum = temparr[temparr.length - 1];
      if (+lastNum === 0) return;
      temparr.splice(temparr.length - 1, 1, +lastNum - 1);
      let upRow = this.dataMap.get(temparr.join("-"));
      if (upRow) {
        this.$post(this.$api.ROLE.AUTHORITY_SORT, {
          roleId: this.roleId,
          menuType: "wfpt",
          menuIds: row.id + "," + upRow.id,
        }).then(() => {
          this.getAuthorityMenu();
        });
      }
    },
    modalShow(visible) {
      if (visible) {
        this.getAuthorityMenu();
      } else {
        this.dataMap.clear();
        this.expandSet.clear();
      }
    },
    getAuthorityMenu() {
      this.$post(this.$api.ROLE.ROLE_AUTHORITY_LIST, {
        roleId: this.roleId,
        menuType: "wfpt",
      }).then((res) => {
        let str = JSON.stringify(res).replace(/childList/g, "children");
        let temp = JSON.parse(str);
        this.dataMap.clear();
        this.dealData(temp);
        this.table.data = temp;
      });
    },
    dealData(tree, prefixKey = "0") {
      for (let i = 0; i < tree.length; i++) {
        let temp = tree[i];
        temp.indexKey = prefixKey + "-" + i;
        i == tree.length - 1 && (temp.end = true);
        this.expandSet.has(temp.id) && (temp._showChildren = true);
        let data = {
          indexKey: temp.indexKey,
          id: temp.id,
          name: temp.name,
          end: temp.end,
        };
        this.dataMap.set(data.indexKey, data);
        if (temp.children && temp.children.length > 0) {
          this.dealData(temp.children, data.indexKey);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-table-row-hover td {
  background-color: #ebf7ff !important;
}
</style>